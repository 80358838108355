.rtwcontainer {
    display: "flex";
    justify-content: "space-between"
}
.empmsg {
    display: flex;
    flex-flow: column;
}
.successMsg {
    display:flex;
}
.successIcon {
    margin-top: 1% !important
}
.successText {
    margin-left: 10px;
}
.submitbtn{
    margin-top: 1rem;
    margin-left: auto;
    position: relative;
    width: 100%;
    min-height: 1px;
}
.msgicon{
    margin: auto 1rem auto 0.5rem;
    display: inline-block;
}

@media (min-width:992px){
    .boxrtw{
        padding-left: 2.5rem; 
        margin-right: 3rem;
    }
}
//Tab
@media (min-width: 768px) {
    .submitbtn {
        max-width: 50%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .submitbtn {
        font-size: small;
        padding: 1rem 0.5rem !important;
    }
}

//Mobile 
@media (max-width:374px){
    .parentWrapper, .datePickerMob{
    padding-left: 2% !important;
    padding-right: 2% !important;
   }
   
   .boxrtw{
        padding:2%;
    }
}
@media (max-width: 767px) {
    .ContainerMb {
        margin-bottom: 4%;
    }
    .msgicon {
        margin-bottom: 5% !important;
    }
    .empmsg {
        text-align: center;
    }
    .deleteIcon {
        margin: 1px 0.3rem 0 0.5rem;
    }
}
