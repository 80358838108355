.scrollable-content {
  max-height: 600px;
  overflow-y: scroll;
}

.popup-width {
  max-width: max-content!important;
}

.section-padding {
  padding:1em;
}

.custom-row {
  // Important rules added to override
  // default Bootstrap grid behavior
  margin-right: 0 !important;
  margin-left: 0 !important;
  @media (max-width: 700px) {
    & {
      display: block !important;
    }
  }
  & > div {
    margin-bottom: 1rem;
  }
  & > div:not(last-child) {
    margin-right: 1rem;
  }
}
