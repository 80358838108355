@import '../../NotesTable/NotesTable.scss';
@import '../../../EmployeeDetails/styles.scss';

.isActiveLink {
  color: white;
  background: #0d3a5c;
  padding: 2px 10px;
  border-radius: 5px;
  text-decoration: none !important;
  &:focus,
  &:hover{
      color: white
  }
}
.offsetGridContainer{
  overflow-y: auto;
  overflow-x: hidden;
  height: 16rem;
  position: relative;
}

.alignRight{
  text-align: right;
}

.logoIcon {
  width: 8.5rem;
  padding-right: 1rem;
}

.printInfoButton{
  font-weight: normal;
  padding-right: 0 !important;
  margin-left: auto;
}

.printInfo {
  font-size: .7rem;
  align-items: flex-start;
}

.paymentRow {
  margin-top: .5rem;
  padding-right: 2rem;
  text-align: justify;
  // transition: transform .5s;
  display: block;
  position: absolute;
  overflow: hidden;
}

.paymentRow[data-expanded=true] {
  opacity: 100;
}

.paymentRow[data-expanded=false] {
  opacity: 0;
  height: 0;
}

.ficaTab {
  padding-left: 1rem;
}

@page {
  margin-top: 1in;
  margin-bottom: .5in;
}
@page :first {
  margin-top: initial;
}

.PaymentInfoTablePrint {
  h2 {
    color: black;
    font-size: 1.2rem;
  }
  
  table {
    overflow-x: auto;
    border: 1px solid lightgrey;
    * {
      font-size: .7rem;
      margin:  0px .25rem;
      padding: .5rem .25rem;
    }
    span {
      margin-left: 0px;
      padding-left: 0px;
    }
  }
}


