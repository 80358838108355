.left {
  margin-right: auto;
}

.right {
  display: flex;
  align-items: center;
}

.rightAlign {
  align-items: center;
  display: flex;
  margin-left: auto;
}
.parent {
  display: flex;
  overflow: hidden;
  align-items: center;
}

.box {
  display: flex;
  flex: 1;
  width: 100%;
}
