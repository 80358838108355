h4.modalheaderText {
    text-transform: none;
    font-weight: 500;
    font-size: 20px;
    color: #37424a;
    font-family: Publico;
}

h1.modalheaderTitle {
    font-weight: 300;
    font-size: 42px;
    color: #37424a;
    font-family: Publico;
}

span.supersetText {
    vertical-align: super;
    font-size: 22px;
}

p.modalcontent {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #37424a;
}

.continueBtn {
    display: inline-flex;
    padding: 1rem;
}

.externalIcon {
    width: 20px;
    padding: 0 0 0 5px;
    fill: #fff;
}

.cancelBtn {
    padding: 1rem;
}

.wrapperContainer {
    width: 100%;
}

@media only screen and (max-width: 991px) {

    h1.modalheaderTitle {
        font-size: 22px;
    }

    p.modalcontent {
        font-size: 14px;
        line-height: 20px;
    }

    .cancelBtn,
    .continueBtn {
        font-size: 15px;
        max-height: 48px;
        line-height: 1.1;
    }

    .externalIcon {
        width: 18px;
        padding: 1px 0 0 5px;
    }

    .wrapperContainer {
        width: 100%;
    }
}