.printIcon:hover {
  cursor: pointer;
}

.printIcon {
  position: absolute !important;
  right: 1rem;
  background: inherit;
  border: none;
}

@media print {
  .printableArea, .printableArea * {
    visibility: visible;
    height: initial !important;
  }
  .printableArea {
    overflow: visible;
    position: absolute !important;
    width: 100vw !important;
    top: 0;
    left: 0;
    display: inherit !important;
  }
}

@media (min-width: 1200px){
  .tableContainer th {
    white-space: nowrap;
  }
}

.hidden {
  display: none;
}