.styledRadioContainer{
    justify-content: start;
}
.successMsg {
    display:flex;
}
.successIcon {
    margin-top: 1% !important
}
.successText {
    margin-left: 1%;
    margin-top: 0.7%;
}