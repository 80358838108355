.noteRow {
  margin-top: .5rem;
  padding-right: 2rem;
  text-align: justify;
  // transition: transform .5s;
  display: block;
  position: absolute;
  overflow: hidden;
}

.noteRow[data-expanded=true] {
  opacity: 100;
  // transform: translateY(0);
}

.noteRow[data-expanded=false] {
  opacity: 0;
  height: 0;
  // transform: translateY(-100%);
}

.noteWrapper {
  // transition: padding-bottom .5s;
}

.icon {
  // This style is to fix the alignment of expand/collapse icon with the column headers. 
  // Should be removed once after the alignment is fixed in the honest component.
  padding-top: 2px!important;
}

.clearFilter {
  width: 100%;
  margin-left: 0rem;
  @media (min-width: 768px) {
    margin-left: -2rem;
  }
  @media (min-width: 992px) {
    margin-left: -1.5rem;
  }
}

.headings {
  button[type=button], i[name=arrow-up], i[name=arrow-down] {
    color: #23669A;
  }
}