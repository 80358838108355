.dateOfDisability {
    min-width: 5rem;
    margin-top: 0.5rem;
    @media (max-width: 460px) {
        margin-top: 1.5rem;
    }
}

.lastDateWorked {
    min-width: 5rem;
    margin-top: 0.5rem;
    @media (max-width: 382px) {
        margin-top: 1.5rem;
    }
}

.returnToWorkModified {
    min-width: 5rem;
    margin-top: 0.5rem;
    @media only screen and (max-width: 397px) {
        margin-top: 0.5rem !important;
    }
    @media only screen and (max-width: 412px) {
        margin-top: 1.5rem;
    }
}