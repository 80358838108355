/* Layout - body full height, main content grows to fit footer pushed to the bottom, main scales to fill space */
.body {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
//  Main contnet grows to fit
.main {
  flex: 1 0 auto;
  &:focus {outline: none}
}

.footer {
  flex-shrink: 0;
}

@media print { 
  .footer {
    display: none;
  }
}

:global(.container) {
  @media(min-width: 768px) {
    width: 960px;
    max-width: 100%;
  }
}