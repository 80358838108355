.containerStyle {
  padding-bottom: 2rem;
  padding-top: 1.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 50%;
  @media screen and (max-width: 900px) {
    width: 90%;
  }
  margin: 0 auto;
}

.alertStyle {
  margin: 0 auto;
  margin-bottom: 1rem !important;
}