.numberCol {
    @media only screen and (max-width: 765px) {
        width: 20%;
        margin-left: 0;
      }
}
.descStepCol {
    @media only screen and (max-width: 765px) {
        width: 80%;
        margin-left: 0;
      }
}
.hyperlink {
  text-decoration: none !important;
}