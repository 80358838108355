.uaDetails {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 15em;
}

@media (min-width: 1200px){
  .tableContainer th {
    white-space: nowrap;  
  }
}