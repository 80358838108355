.button {
  width: 100%;
}

/* Create two equal columns that floats next to each other */
.dateColumn {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.dateRow:after {
  content: "";
  display: table;
  clear: both;
}