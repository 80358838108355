.dataSection {
  padding: 1rem;
  border-color: lightgray;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.dataSection:not(:first-child) {
  //border-left: solid 1px lightgray; 
}

.noBottomPadding {
  padding-bottom: 0rem;
}

.name {
  background-color: #f5f5f5;
}

.employeeDetailContainer {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.info {
  // display: flex;
  // justify-content: flex-start;
  margin-bottom: .5rem;
}

.infoLabel {
  margin-right: 1rem;
  white-space: nowrap;
}

.lfg-logo {
  width: 8.5rem;
  padding-right: 1rem;
  position: absolute;
}

@media screen and (max-width: 992px){

  .employeeDetailContainer{
    display: block;
  }
}

@media print and (max-width: 992px){ 
  .employeeDetailContainer {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;

    .dataSection {
      padding: .5rem;
      font-size: .6rem;
      .name {
        font-size: 1.2rem;
        font-style: normal;
      }
      .info {
        border-right: 1px solid lightgrey;
      }
      &:last-child {
        flex: 2;
        .info {
          border-right: none;
          span {
            flex: 2;
          }
        }
      } 
    }
  }
}