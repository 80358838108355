.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.allTableFilterGroup {
  font-size: 0.9rem;
  margin: 0.5rem 0;
  border: 1px solid #d8d8d8;
  display: inline-block;
  padding: 0.75rem;
  position: relative;
}

// TODO - retire this style when AllTable is gone for good (leave in case we ever get rid of AllTable

// .allTableFilterGroup input{
//   margin-right: 0.25rem;
//   margin-left: 1.5rem;
// }

// .allTableFilterGroup label:first-of-type input{
  // margin-right: 0.25rem;
  // margin-left: 0;
// }

.allTableFilterHeader {
  font-weight: bold;
  position: absolute;
  top: -.5rem;
  background-color: white;
  padding: 0 .25rem;
}

@media (min-width: 1200px){
  .tableContainer th {
    white-space: nowrap;  
  }
}


.iconClass {
  &:hover{
    text-decoration: none !important;
  }
}