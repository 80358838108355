.logo {
  font-size: 68px;
  font-weight: 500;
  text-transform: lowercase;
}

.logoIcon {
  margin-right: 12px;
  height: 100px;
}

.container {
  min-height: calc( 100vh - 400px );
  width: 100%;
  // background-color: #f6f6e8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.message {
  display: flex;
  align-items: center;
}
