// All the styles for a NavItem - the button for the NavLink or dropdown Navmenu
.navItem {
    margin-left: 0px !important;
    font-weight: 400;
    display: inline-block;
    // height: 100%;
    // max-height: 50px;
    // height: 72px !important;
    display: flex;
    align-items: flex-end;
    text-align: initial;
  }
