.numberCircle {
   background: #fff;
   border-radius: 50%;
   border: 2px solid #37424A;
   color: #822433;
   font-size: 24px;
   font-weight: bold;
   height: 60px;
   padding: 15px;
   text-align: center;
   width: 60px;
};