.rowContent {
  margin-top: .5rem;
  padding-right: 2rem;
  display: block;
  position: absolute;
  overflow: hidden;

  &[data-expanded=true] {
    opacity: 100;
    width: 90%;
  }
  
  &[data-expanded=false] {
    opacity: 0;
    height: 0;
  }
}

.icon {
  // This style is to fix the alignment of expand/collapse icon with the column headers. 
  // Should be removed once after the alignment is fixed in the honest component.
  padding-top: 2px!important; 
}

.headings {
  button[type=button], i[name=arrow-up], i[name=arrow-down] {
    color: #23669A;
  }
}

.detailsRowContentWithBackground {
  margin: 0rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  background-color: #EEEEEE;
}

.detailsRowContent {
  margin: 0rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.tableRowContent {
  padding-left: 5rem;
}

.dt {
  font-weight: bold;
}
