$checkmarkSize: 21px;
$checkmarkBorderSize: 1px;
$dotSize: 7px;

.radio-label {
  position: relative;

  .radio-text {
    margin-left: 35px;
    line-height: (2 * $checkmarkBorderSize) + $checkmarkSize;
  }
  
  input {
    opacity: 0;
    position: absolute;
  }
  
  input + span.checkmark {
    background-color: white;
    display: block;
    width: $checkmarkSize;
    height: $checkmarkSize;
    border-radius: 50%;
    position: absolute;
    border: $checkmarkBorderSize solid #ccc;
    box-sizing: content-box;
  }
  
  input:checked + span.checkmark {
    background-color: rgb(10, 39, 95);

    &:after {
      content: '';
      display: block;
      width: $dotSize;
      height: $dotSize;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: calc(($checkmarkSize - $dotSize) / 2);
      right: calc(($checkmarkSize - $dotSize) / 2);
    }
  }
}