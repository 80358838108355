.uploadButton {
    cursor: pointer;
    position: relative;
    display: inline-block;
    background-color: #fff;
    border: 2px solid #23669a;
    color: #000;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    padding: 12px;
}

.uploadDisabledButton {
    position: relative;
    display: inline-block;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    color: rgba(0, 0, 0, 0.247);
    border-color: rgba(35, 102, 154, 0.5);
    cursor: default;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    padding: 12px;
}

.leftWidthStyling {
    @media only screen and (max-width: 765px) {
        width: 60%;
        margin-left: 0;
    }
}