.flipH {
    transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.noUnderline {
    i {
        text-decoration: none !important;
        &:hover { text-decoration: none !important; }
        &:visited { text-decoration: none !important; }
        &:active { text-decoration: none !important; }
        &:focus { text-decoration: none !important; }
    }
}

/* Code cleanup pending - move these hacks into Honest*/

/* For HeaderDropdown button, to get it the right size" */
.dropdownButtonFix {
    padding: .78125rem !important;
    border: none;
}

.fontFix {
    font-size: .8125rem !important;
}

.paddingFix {
    padding: none;
}

.alignLeft{
    text-align: left;
}