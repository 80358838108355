.leftWidthStyling {
    @media only screen and (max-width: 765px) {
        width: 60%;
        margin-left: 0;
      }
}
.rightWidthStyling {
  @media only screen and (max-width: 765px) {
      width: 40%;
      margin-left: 0;
    }
}
